<template>
  <div class='app'>
    <div class='app__content'>
      <slot />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.app {
  padding: 25px 30px;

  background-color: var(--bg-secondary);

  &__content {
    min-height: 100vh;
    max-width: 2000px;

    padding: 20px 25px;

    border-radius: 15px;
    background-color: var(--bg-primary);
  }
}
</style>
